$main_font: 'Montserrat', sans-serif;

$sidebar_width: 280px;
$navbar_height: 70px;

$table_bg: #f8f9fa;

$s_bp: 320px;
$m_bp: 768px;
$l_bp: 1024px;
$xl_bp: 1200px;
$xxl_bp: 1600px;

$btn_link_disabled_text_color: #BCC4D8;

$attention: #d81d1d;

@import "./bootstrap/bootstrap.scss";

@import "./analytics";
@import "./buttons";
@import "./brand-modal.scss";
@import "./common";
@import "./configuration.scss";
@import "./external";
@import "./form.scss";
@import "./global";
@import "./home";
@import "./http-error";
@import "./login";
@import "./orders";
@import "./sidebar";
@import "./table-component.scss";
@import "./text.scss";
@import "./tree.scss";
