.tile-container {
  padding: 10px 0 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-bottom: 1px solid $light_gray;
}

.counter-tile {
  display: flex;
  margin: 0 15px 20px;
  position: relative;

  &--warning {
    font-size: 35px;
    line-height: 1;
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 2;
  }

  &__icon {
    color: $icon_color;
    position: relative;
    background-color: #ffffff;
    width: 80px;
    height: 80px;

    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.1);

    text-align: center;
    font-size: 36px;
    line-height: 78px;

    margin-left: auto;
    margin-right: 10px;

    transition: .3s;
  }

  &__total {
    color: $dark;
    font-size: 60px;
    margin: 0 10px;
  }

  &__title {
    margin: 0;
    font-size: 11px;
    letter-spacing: 3px;
  }

  &__subtitle {
    margin: 0;
    color: $dark;
    font-size: 20px;
  }

  &:hover {
    .counter-tile__icon {
      cursor: pointer;
      border-color: transparent;
      -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
      -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
      box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    }
  }
}

.summary {
  margin-top: 60px;

  &__title {
    text-transform: uppercase;
  }

  td {
    height: 82px;
  }
}


.slider {
  padding: 40px 55px 0;

  .rc-slider-mark-text {
    color: $dark;
    font-size: 13px;
  }
}

@media (max-width: $xxl_bp) {
  .counter-tile {
    flex-direction: column;
    &__icon {
      margin: 0 auto;
    }
    &__total {
      margin-left: 0;
    }
  }
}
