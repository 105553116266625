.tree-node {
  &:hover {
    cursor: pointer;
  }
  fill: $primary;
}

.tree-node__text {
  fill: $primary_contrast_color;
  text-anchor: middle;
  font-family: $main_font;
  &:hover {
    cursor: pointer;
  }
}

.tree-link {
  stroke: $dark;
  stroke-width: 1;
  fill: none;
}

.tree-background {
  &:hover {
    cursor: crosshair;
  }
  fill: #ffffff;
}

.minimap-background {
  fill: $light_gray;
  fill-opacity: 0.8;
}

.minimap-reference {
  fill: $primary_contrast_color;
  fill-opacity: 0.5;
  stroke: $dark;
  stroke-width: 2;
}

.zoom-controls {
  display: flex;
  justify-content: flex-end;
}
