$primary: #07AAE2;
$primary_contrast_color: #ffffff;
$secondary: $primary;

$dark: #182C4F;
$alert: #e03535;

$gray: #a6a6a6;
$light_gray: lighten($gray, 30%);
$dark_gray: darken($gray, 30%);

$sidebar_bg_color: #5f6779;
$sidebar_user_icon_color: $primary;
$sidebar_option_icon_color: #ffffff;
$sidebar_option_text_color: #ffffff;
$sidebar_active_text_color: #182c4f;
$sidebar_border_color: $sidebar_bg_color;
$sidebar_menu_bg_color_active: $primary;

$table_text_header_color: $primary;
$header_color: #ebf6f8;
$base_text_color: #5F6779;
$btn_primary_hover_bg_color: #182C4F;
$btn_primary_hover_color: $primary_contrast_color;
$input_bg_color: #ebf6f8;
$login_bg_color: #ffffff;
$btn_link_bg_color: #ebf6f8;
$btn_link_active_text_color: $primary;
$btn_link_coming_soon_text_color: $primary;
$splash_background_color: $primary;

$icon_color: $primary;

@import '../theme/main.scss';
