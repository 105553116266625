
.login{
  flex: auto;
  display: flex;
  align-items: center;
  background-color: $login_bg_color;

  &__header {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &__screen {
    -webkit-box-shadow: 0 0 35px rgba(24,44,79,.1);
    -moz-box-shadow: 0 0 35px rgba(24,44,79,.1);
    box-shadow: 0 0 35px rgba(24,44,79,.1);
    background-color: #ffffff;

    border-style: solid;
    border-color: $primary;
    border-width: 1px;
  }

  &__title {
    font-size: 30px;
  }

  &__input {
    border-radius: 17px;
    background-color: $input_bg_color;
    color: $base_text_color;
    border-color: #ffffff;

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: $input_bg_color;
      border-color: #ffffff;
    }
  }

  &__error {
    color: #D61D1D;
    font-size: 18px;
    font-weight: 700;
    padding: 1.5rem 1.5rem 0;
    text-align: center;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .login {
    height: 78vh;
  }
}
