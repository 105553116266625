.user {
  font-size: 17px;
  text-align: left;

  &__icon {
    margin-right: 20px;
    width: 30px;
    font-size: 18px;
    color: $sidebar_user_icon_color;
    vertical-align: top;
    min-width: 28px;
  }
}

.sidebar-wrapper {
  position: fixed;
  height: 100%;
  z-index: 3;
}

.sidebar {
  color: #ffffff;
  background: $sidebar_bg_color;
  border-right: 1px solid $sidebar_border_color;
  height: 100%;
  overflow-y: auto;
  width: $sidebar_width;

  &--closed {
    display: none;
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
  }

  &__user {
    display: flex;
    flex-wrap: nowrap;
    padding: 25px 30px;
    height: 120px;
    line-height: 1.2;
  }

  &__icon {
    margin-right: 20px;
    width: 30px;
    font-size: 18px;
  }

  &__options {
    display: flex;
    flex-flow: column;
  }

  &__option {
    padding: 10px 30px;
    line-height: 30px;
    &:hover {
      .sidebar__option-icon {
        opacity: 1;
      }
    }
  }

  &__option-icon {
    margin-right: 20px;
    min-width: 30px;
    font-size: 18px;
    vertical-align: text-top;
    opacity: .8;
    transition: .3s;
    &--active {
      opacity: 1;
    }
  }

  &__option-link {
    &:hover {
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;
    }
  }

  &__option-text {
    font-weight: 500;
  }

  &__text {
    color: $sidebar_option_text_color;
    line-height: 1.2;
  }

  &__option-text--active {
    font-weight: 600;
    color: $sidebar_active_text_color;
  }

  &__icon {
    color: $sidebar_option_icon_color;
  }

  &__option-icon--active {
    opacity: 1;
    color: $sidebar_active_text_color;
  }
}

.user {
  &__title {
    margin-top: 8px;
  }
}
