.order-details {
  .table {
    tr:last-child td {
      padding-bottom: 5px;
    }
  }

  &__lines {
    margin-bottom: 0;
  }

  &__footer {
    display: flex;
    flex-flow: column;
    text-align: right;
    margin-right: 10px;
  }

  &__line-img {
    width: 40px;
    height: 40px;

    background-color: $light;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__approval-actions {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__basic-information {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }

  &__return-btn {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-left: 16px;
  }

  &__track-btn {
    height: 50px;
  }

  .remove-line-td {
    padding-left: 1.5rem;

    .remove-btn {
      padding: 4px 8px;
    }
  }
}

.search {
  &__input {
    &:focus {
      box-shadow: none;
    }
  }

  &__btn-container {
    &:hover {
      background-color: $light;
    }
  }
}

.order__approvers span:not(:first-child) {
  display: inline;
  justify-content: flex-end;
}

.order__approvers span:not(:first-child)::before {
  content: ", "
}

.pending-order--danger {
  color: $alert;
}

.pending-order--primary {
  color: $primary;
}

.approval-rules {
  &__activate-container {
    display: flex;
    flex-direction: column;
    padding: 48px 16px;
    align-items: center;
  }

  &__activate-btn {
    margin-top: 16px;
  }

  &-form {
    .section {
      margin-bottom: 40px;
    }

    .form-group {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.remove-order-line-modal {
  padding-top: 12rem;
  text-align: center;

  .modal-content {
    padding: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    width: 109%;

    .modal-body {
      .remove-modal {
        &-danger {
          color: $attention;
        }

        h3 {
          font-weight: bold;
        }

        div {
          .btn-round {
            margin: 38px;
          }
          .btn-link-custom {
            text-decoration: underline;
            color: $text-body-color;
            text-transform: none;
          }
        }
      }
    }
  }
}
