.dashboard-tabs {
  .nav-tabs {
    .nav-link {
      color: #000000;
      font-weight: 500;
      border: none;

      &.active {
        border-bottom: 3px solid $primary;
      }

      &:hover {
        border-bottom: 3px solid $primary;
      }
    }
  }
}
$desktop-domo: 960px;
@function vmax($scalar) {  
  @return calc(#{$scalar} * (1vw + 1vh - 1vmin));  
}
.domo-iframe {
  &--total-spend {
    height: vmax(610);
    @include media-breakpoint-up(md) {
      height: 1200vw;
    }
    @media (min-width: $desktop-domo) {
      height: 120vw;
    }
  }
  &--open-orders {
    height: vmax(400);
    @include media-breakpoint-up(md) {
      height: 700vw;
    }
    @media (min-width: $desktop-domo) {
      height: 110vw;
    }
  }
  &--order-status {
    height: vmax(420);
    @include media-breakpoint-up(md) {
      height: 780vw;
    }
    @media (min-width: $desktop-domo) {
      height: 80vw;
    }
  }
  &--service-quality {
    height: vmax(380);
    @include media-breakpoint-up(md) {
      height: 680vw;
    }
    @media (min-width: $desktop-domo) {
      height: 90vw;
    }
  }
}
