.thin {
  font-weight: 300;
}

.medium-bold {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.huge-text {
  font-size: 20px;
}

.medium-text {
  font-size: 14px;
}

.small-text {
  font-size: 13px;
}

.tiny-text {
  font-size: 12px;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-base {
  color: $base_text_color;
}
