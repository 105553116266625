.header {
  padding: 25px 30px;
  margin-bottom: 30px;
  background-color: $header_color;

  &__message {
    margin: 0;
    font-size: 20px;
    line-height: 1;
    letter-spacing: .4em;
    &:after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 2px;
      background: $primary;
      vertical-align: middle;
      margin-left: 10px;
      position: relative;
      top: -2px;
    }
  }

  &__title {
    margin: 2px 0 0;
    color: $dark;
    font-size: 44px;
    line-height: 48px;
  }
  &__subtitle {
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }
}


.company-logo {
  height: 50px;
  object-fit: contain;

  &--extra-small {
    width: auto;
    height: auto;
    max-width: 240px;
    max-height: 40px;
  }

  &--small {
    width: auto;
    height: auto;
    max-width: 250px;
    max-height: 60px;
  }

  &--medium {
    width: auto;
    height: auto;
    max-width: 255px;
    max-height: 65px;
  }

  &--big {
    width: auto;
    height: auto;
    max-width: 320px;
    max-height: 80px;
  }
}

.footer {
  padding: 20px 20px 0;

  &__info {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__contact{
    padding-top: 10px;
    margin-left: 30px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

  }

  &__links {
    display: flex;
    padding-left: 48px;
    align-items: center;
  }

  &__information-link {
    margin-right: 24px;
    color: #000;

    &:hover {
      color: $primary;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    letter-spacing: 1px;
    margin-bottom: 0;
    font-size: 16px;
    &:hover{
      color: $primary;
      text-decoration: none;
    }
    &--email {
      color: $dark;
    }
    &--phone {
      color: #a4a4a4;
      letter-spacing: 1px;
    }
  }

  &__copyright {
    line-height: 17px;
  }
}

.spinner {
  margin-top: 20px;
  height: 45px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-logout {
  padding: 15px 0;
  border: none;
  color: $gray;
  font-size: 16px;
  line-height: 40px;
  text-transform: capitalize;

  &__icon {
    margin-right: 10px;
    font-size: 18px;
    vertical-align: text-top;
  }

  &:hover {
    color: $alert;
  }
}

.navbar-wrapper {
  position: fixed;
  z-index: 2;
  width: calc(100% - #{$sidebar_width});
  right: 0;
  &--sidebar-closed {
    width: 100%;
  }
}

.navbar {
  padding: 0;
  height: $navbar_height;
  width: 100%;

  &-nav {
    margin-right: 30px;
  }

  &-toggler {
    padding: 15px;
    border: none;
    background: none;
    width: $navbar_height;
    height: $navbar_height;

    &:focus {
      outline: none;
    }

    &:hover {
      color: $primary;
    }

    svg {
      font-size: 24px;
    }
  }
}


.nav-item {
  position: relative;
}

.coming-modal {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-top: 8px;
  min-width: 320px;

  &__section {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-header {
    color: $btn_link_coming_soon_text_color;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 8px;
  }

  &__content {
    padding-left: 32px;
  }

  &__point {
    margin-bottom: 4px;
    list-style-type: disc;
  }
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 30px;
  border-radius: 4px;
  font-size: 14px;
  background: $table_bg;
  &__item {
    padding: 0;
    font-weight: 500;
    line-height: 20px;
    &:after {
      content: "/";
      margin: 0 10px;
      font-weight: 400;
      line-height: 1;
      vertical-align: middle;
    }
    &:last-of-type:after {
      display: none;
    }
  }
  &__link {
    color: $dark_gray;
    font-weight: 400;
    text-decoration: none !important;
    transition: .3s;
    &:hover {
      color: $primary;
    }
  }
}

.search {
  &__input {
    height: 40px;
  }
  &__btn-container {
    padding: 0 5px;
  }
}

.radio-button {
  input {
    margin-right: 5px;
  }
}
