body {
  font-family: $main_font;
  min-height: 100vh;
  display: flex;
  margin: 0px;
  padding: 0px;
}

#root {
  display: flex;
  flex: auto;
  overflow: hidden;
}

.body-content {
  display: flex;
  flex-flow: row;
  flex: auto;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-flow: column;
  flex: auto;
  overflow: hidden;
}

.content {
  flex: auto;
  display: flex;
  flex-flow: column;
  overflow-x: auto;
}

.content-wrapper {
  flex: auto;
  display: flex;
  overflow: hidden;
  height: 100vh;
  padding-top: $navbar_height;
  padding-left: $sidebar_width;

  &--sidebar-closed {
    padding-left: 0;
  }
}

.content__container {
  padding: 0 15px;
  flex: auto;
}

.edit-node__container {
  padding: 16px;
  max-width: 400px;
}

a {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.reject-modal__textarea {
  width: 100%
}

.confirmation-modal__buttons {
  margin-top: 10px;
}

.confirmation-modal__confirm-btn {
  margin-left: 10px;
}

.splash {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 4;

  background-color: $splash_background_color;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 25px;
  }

  &__spinner {
    color: #ffffff;
    font-size: 37px;
  }
}

.trimmed-ul {
  padding: 0 16px 16px 16px;
  margin-bottom: -10px;
}

.background-primary {
  background-color: $sidebar_menu_bg_color_active !important;

  &:focus {
    background-color: $sidebar_menu_bg_color_active !important;
  }
}

.company-configuration-form {
  width: 100%;
  max-width: 400px;
  &__actions-container {
    display: flex;
    padding-top: 16px;
  }
}

.main-navbar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  padding-top: 24px;
  padding-bottom: 8px;
  justify-content: space-between;
  margin: 0 auto;
}

.admin-navigation-links {
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.admin-navigation {
  &__item {
    margin-right: 8px;
  }
}

.admin-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;

  &__title-container {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    margin-bottom: 0;
    padding-left: 4px;
    color: #444444;
  }

  &--logout {
    display: flex;
    flex-direction: row-reverse;
    max-width: none;
  }
}

.mcc-admin-container {
  padding: 0;
}

.loading-row-placeholder {
  &__rectangle {
    height: 13px;
    width: 100%;
  }

  &__circle {
    width: 26px;
    height: 26px;
    border-radius: 100%;
  }

  @keyframes shimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }

  animation-duration: 2s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px;
  position: relative;
}
