.configuration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.configuration-option {
  display: flex;
  flex-flow: column;
  width: 250px;
  height: 250px;
  align-items: center;
  padding: 30px;
  transition: .3s;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,.1);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,.1);
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    min-height: 50px;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    color: $dark;
  }

  &__icon {
    font-size: 40px;
    align-self: center;
    margin: 10px 0 20px;
    color: $icon_color;
  }

  &__description {
    text-align: center;
    margin-bottom: 0;
  }
}
