// Bootstrap modal

.modal-content {
  border: none;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,.2);
  -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,.2);
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,.2);
}
.modal-header, .modal-body, .modal-footer {
  padding: 15px 20px;
}
.modal-header .modal-title {
  color: $primary_contrast_color;
  line-height: 31px;
}
.modal-header .close {
  padding: 3px;
  margin: 0;
  color: $primary_contrast_color;
  transition: .3s;
}
.modal-footer:empty {
  display: none;
}
.modal-header {
  background-color: $primary;
}

// Bootstrap pagination

.input-group-append .btn {
  z-index: 0;
}

.Toastify__toast--success {
  background: $primary !important;
}

.rc-slider-track {
  background-color: lighten($primary, 10%) !important;
}

.rc-slider-handle, .rc-slider-dot-active {
  border-color: lighten($primary, 10%) !important;
}

.table-component {
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__container--open {
    position: static;
  }

  .react-autosuggest__input {
    height: $input-height;
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $base_text_color;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 90%;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    min-width: 90%;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    color:$base_text_color;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-width: 600px;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow: auto;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    white-space: break-spaces;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $light_gray;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: $primary;
    border: none;
  }

  .CalendarDay__selected_span {
    background-color: lighten($primary, 10%);
  }


  .CalendarDay__selected_span:hover, .CalendarDay__selected_span:hover{
    background-color: lighten($primary, 10%);
    border: none;
  }

  .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background-color: lighten($primary, 10%);
    color: #ffffff;
    border: none;
  }

  .DayPickerKeyboardShortcuts_show {
    display: none;
  }

  .DateInput, .DateInput_input__small, .DateRangePickerInput__withBorder {
    border-radius: 0.25rem;
    background-color: #ffffff;
  }

  .DateInput__small {
    width: 75px;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $primary;
  }

  .DayPicker_transitionContainer {
    height: 318px !important;
  }
}

.css-1mx788c-multiValue {
  background-color: $primary !important;
  color: $primary_contrast_color !important;
}
