$text-body-color: #4B515D;
.brand-modal {
  .modal-content {
    border-radius: .5rem;
  }

  &--bg-dark {
    .modal-header {
      .modal-title, .close {
        color: var(--light);
      }
    }
  }

  .modal-header {
    height: 72px;
    border-radius: .5rem .5rem 0 0;

    .modal-title {
      font-size: 18px;
      line-height: 21.94px;
      font-weight: 600;
      border-radius: .5rem;

      &--small {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.07px;
      }
    }
    .close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  .modal-footer {
    border-radius: 0 0 .5rem .5rem;
  }

  .modal-body {
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    color: $text-body-color;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 81px;

    &.center-body {
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
    }

    .list-unstyled {
      margin: 0;
    }
    .row {
      max-height: 264px;
      height: 100%;
    }
  }

  .divider-container {
    padding: 0 28px;
  }
}

.shared-modal {
  border-radius: 8px;

  .modal-content {
    border-radius: 8px ;
  }

  .modal-header {
    border-radius: 8px 8px 0 0;
  }
}
