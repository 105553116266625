.http-error {
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;

    &--title {
      background-color: $header_color;
      padding: 25px 30px;
    }
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: .4em;
    font-size: 19px;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 2px;
      vertical-align: middle;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
  }

  &__subtitle {
    font-size: 45px;
  }

  &__detail {
    font-size: 20px;
    line-height: 1.4;
  }
}
