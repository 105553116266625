
.btn {
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  &__icon {
    margin-right: 5px;
  }

  &--round {
    border-radius: 100px;
  }
}

.btn-link {
  color: $primary;
  text-decoration: none !important;
  outline: none;

  &:hover, &:active {
    color: $btn_link_active_text_color;
    background: $btn_link_bg_color;
  }

  &:disabled, &.disabled {
    color: $btn_link_disabled_text_color;
  }
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;

  color: $primary_contrast_color;

  &:hover {
    background-color: $btn_primary_hover_bg_color;
    border-color: $btn_primary_hover_bg_color;
    color: $btn_primary_hover_color;
  }
}

.btn-round {
  border-radius: 17px;
  padding-left: 17px;
  padding-right: 17px;
  letter-spacing: 2px;
}

.btn--disabled {
  opacity: .6;

  &:hover {
    cursor: auto;
  }
}

.btn--spaced {
  margin-left: 8px;
  margin-right: 8px;
}
