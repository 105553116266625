.table-component {
  &__header-cell {
    white-space: nowrap;
    word-wrap: break-word;
    vertical-align: top !important;
  }

  &__autosuggest-component {
    position: relative;
  }

  &__data-cell {
    font-family: $font-family-base;
  }

  &__header-cell {
    background: white;
  }

  &__header-button {
    padding: 5px 0;
    text-transform: none;
    color: $table_text_header_color;
    border: none;
    font-weight: 600;
    transition: .3s;
    &:focus {
      box-shadow: none;
    }
  }

  &__header-chevron {
    min-width: 15px;
    text-align: center;
    margin-right: 5px;
    opacity: .5;
  }

  &__header-button--not-clickable {
    cursor: default !important;
    &:hover {
      color: inherit;
    }
  }

  &__header-button--clickable {
    &:hover {
      color: $dark;
    }
  }

  &__header-filter {
    margin-left: 10px;
    color: $gray;
    &:hover {
      color: $dark_gray;
    }
  }

  &__header-checkbox {
    margin-bottom: 0px;
    margin-top: 8px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    padding: 0;
    width: 26px;
    height: 26px;
    font-size: 12px;
    line-height: 1;
    border-radius: 50%;
    padding-top: 0px;
    padding-left: 0px;
  }

  &__action--danger {
    border-color: $alert;
    background-color: $alert;
    color: #ffffff;
  }

  &__link {
    text-decoration: underline;
  }

  .min-vw-50 {
    min-width: 50vw;
  }
}

.table-tools {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid $light_gray;
  margin-bottom: 20px;
  &__search {
    flex: 1 1 auto;
    max-width: 320px;
    margin-right: 20px;
  }
  &__btn {
    margin-left: 5px;
  }
}

.pagination-wrapper {
  display: flex;
  min-height: 30px;
  margin-bottom: 10px;
}

.pagination-info {
  flex: 1 1 auto;
  line-height: 30px;
  font-size: 13px;
  color: $gray;
}

.pagination {
  margin: 0;
  &__item {
    border-radius: 4px;
    overflow: hidden;
    font-size: 12px;
    margin-left: 5px;
  }
  &__btn {
    padding: 0 8px;
    height: 30px;
    border: none;
    font-size: 12px;
    font-weight: 500;
    min-width: 30px;
    line-height: 30px;
    &:focus {
      box-shadow: none;
    }
    &:hover:not(.btn--disabled) {
      background: $input_bg_color;
    }
    &--active {
      padding: 0 8px;
      height: 30px;
      border: none;
      min-width: 30px;
      line-height: 30px;
      font-weight: 500;
      background: $primary;
      color: white;
      border: none;
    }
  }
  &__btn-last::before {
    content: '...';
    position: relative;
    display: inline-block;
    margin-right: 3px;
  }
  &__btn-head::after {
    content: '...';
    position: relative;
    display: inline-block;
    margin-left: 3px;
  }
}

.table-loading {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, transparent 60%, #fff);
  }
}
