.assign-table {
  overflow: auto;
  height: 70vh;
  margin-bottom: 10px;
}

.remove-button {
  font-size: 10px;

  &.close {
    padding-left: 3px;
  }
}

.form-field {
  &__label--required::after{
    color: $alert;
    content: "*";
  }

  &__error {
    color: $alert;
  }

  &--show-password {
    position: relative;
    margin: -25px 10px 0 0;
    z-index: 2;
  }
}

form button[type=submit] {
  float: right;
}

.multiselect {
  width: 100%;
  height: 100% !important;
  padding: 0;
  overflow-y: auto;

  &__option {
    color: $primary;
    background-color: $white;
    padding: .2rem .5rem;
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    &:checked, {
      position: relative;
      background: linear-gradient($primary, $primary) !important;
      color: $primary_contrast_color !important;

      // Hack to style select options, this may not work in other old browsers,
      // but isn't required and the empty string will take care of working as a fallback
      &:before {
        content: attr(data-text) "";
        position: absolute;
        color: $primary-contrast_color
      }
    }
  }

  &__button {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: underline !important;
  }
}

.invalid-feedback--active {
  display: block;
}
